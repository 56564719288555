<template>
    <div class="main">
        <div class="d-flex justify-content-center" v-if="loading">
            <b-spinner variant="success" label="Spinning" style="width: 3rem; height: 3rem"></b-spinner>
        </div>
        <b-card v-if="!loading">
            <b-row>
                <b-col cols="12">
                    <div class="d-flex justify-content-end my-1">
                        <b-button to="/servicerequest/add" variant="primary"> Add Request </b-button>
                    </div>
                </b-col>
                <b-col md="2" sm="4" class="my-1">
                    <b-form-group class="mb-0">
                        <label class="d-inline-block text-sm-left mr-50">Per page</label>
                        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
                    </b-form-group>
                </b-col>

                <b-col md="10" class="my-1">
                    <b-form-group label="Filter" label-cols-sm="8" label-align-sm="right" label-size="sm"
                        label-for="filterInput" class="mb-0">
                        <b-input-group size="sm">
                            <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                            <b-input-group-append>
                                <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>

                <b-col cols="12">
                    <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="services"
                        :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
                        :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                        <template #cell(itemId)="data">
                            {{ getProduct(data.item.itemId) }}
                        </template>
                        <template #cell(leadClientId)="data">
                            {{ getCustomer(data.item.leadClientId) }}
                        </template>
                        <template #cell(assignedTo)="data">
                            {{ getSalePersonName(data.item.assignedTo) }}
                        </template>
                        <template #cell(status)="data">
                            <b-badge v-if="data.item.status === 0" variant="info">
                                Pending
                            </b-badge>
                            <b-badge v-if="data.item.status === 1" variant="primary">
                                APPROVED
                            </b-badge>
                            <b-badge v-if="data.item.status === 2" variant="danger">
                                CANCELLED
                            </b-badge>
                        </template>
                        <template #cell(actions)="data">
                            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                    <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                                </template>
                                <b-dropdown-item :to="getViewLink(data.item.id)">
                                    <feather-icon icon="EyeIcon" class="mr-50" />
                                    <span>View</span>
                                </b-dropdown-item>
                                <b-dropdown-item :to="getEditLink(data.item.id)" v-if="data.item.status === 0">
                                    <feather-icon icon="Edit2Icon" class="mr-50" />
                                    <span>Edit</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="approve(data.item.id)">
                                    <feather-icon icon="CheckIcon" class="mr-50" />
                                    <span>Approve</span>
                                </b-dropdown-item>

                                <b-dropdown-item @click="cancel(data.item.id)">
                                    <feather-icon icon="TrashIcon" class="mr-50" />
                                    <span>Cancel</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </b-table>
                </b-col>

                <b-col cols="12">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
                        class="my-0" />
                        <b-form-group>
      Showing {{ perPage * (currentPage - 1) + 1}} 
      to {{ Math.min(perPage * currentPage, totalRows) }} 
      of {{ totalRows }} entries
        </b-form-group>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
  
<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import {
    BCard,
    BButton,
    BTable,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BSpinner,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BBadge,
} from "bootstrap-vue";


export default {
    components: {
        BTable,
        BAvatar,
        BCard,
        BDropdown,
        BBadge,
        BRow,
        BCol,
        BSpinner,
        BFormGroup,
        BFormSelect,
        BPagination,
        BDropdownItem,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
    },
    data() {
        return {
            perPage: 5,
            token: localStorage.getItem("accessToken"),
            pageOptions: [3, 5, 10],
            totalRows: 0,
            currentPage: 0,
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            filter: null,
            filterOn: [],
            infoModal: {
                id: "info-modal",
                title: "",
                content: "",
            },
            fields: [
                { key: "reference", label: "ReferenceNo", sortable: true },
                { key: "itemId", label: "Service", sortable: true },
                { key: "leadClientId", label: "Customer", sortable: true },
                { key: "assignedTo", label: "Staff", sortable: true },
                "startDate",
                { key: "status", label: "Status", sortable: true },
                { key: "actions", label: "Actions", sortable: true },
            ],
        };
    },
    computed: {
        ...mapGetters("serviceModule", {
            services: "servicerequests",
            loading: "loading",
        }),
        ...mapGetters("productModule", {
            productList: "product",
            loading: "loading",
        }),
        ...mapGetters("customerModule", {
            customerList: "customers",
            loading: "loading",
        }),
        ...mapGetters("salesPersonModule", {
            SalesPersonList: "salesperson",
            loading: "loading",
        }),
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter((f) => f.sortable)
                .map((f) => ({ text: f.label, value: f.key }));
        },
    },
    async mounted() {
        await this.getServiceRequestListAction().then(() => {
            this.totalRows = this.services.length;
            this.services.forEach((element) => {
                //return date as dd/mm/yyyy
                element.startDate = new Date(element.startDate).toLocaleDateString();
            });
        });
        await this.getProductListAction();
        await this.getCustomersListAction();
        await this.getSalesPersonListAction();
    },
    methods: {
        ...mapActions("customerModule", ["getCustomersListAction"]),
        ...mapActions("serviceModule", ["getServiceRequestListAction"]),
        ...mapActions("productModule", ["getProductListAction"]),
        ...mapActions("productModule", ["getProductListAction"]),
        ...mapActions("salesPersonModule", ["getSalesPersonListAction"]),

        getViewLink(id) {
            return "/servicerequest/view/" + id;
        },
        async approve(id) {
            try {
                const url = process.env.VUE_APP_API_URL
                const response = await axios.put(url + `v1/servicerequests/${id}?status=1`, null, {
                    headers: {
                        'Authorization': `Bearer ${this.token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (response.status === 200) {
                    this.$swal({
                        title: "Success",
                        text: "Service request approved successfully",
                        icon: "success",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });


                    // Update the local data with the approved status
                    const serviceRequest = this.services.find(s => s.id === id);
                    if (serviceRequest) {
                        serviceRequest.status = 1;
                    }
                }
            } catch (error) {
                this.$swal({
                    title: "Error",
                    text: error.response.data.message,
                    icon: "error",
                    customClass: {
                        confirmButton: "btn btn-danger",
                    },
                    buttonsStyling: false,
                });
            }
        },

        async cancel(id) {
            try {
                const url = process.env.VUE_APP_API_URL
                const response = await axios.put(url + `v1/servicerequests/${id}?status=2`, null, {
                    headers: {
                        'Authorization': `Bearer ${this.token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (response.status === 200) {
                    this.$swal({
                        title: "Success",
                        text: "Service request cancelled successfully",
                        icon: "success",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });

                    // Update the local data with the approved status
                    const serviceRequest = this.services.find(s => s.id === id);
                    if (serviceRequest) {
                        serviceRequest.status = 2;
                    }
                }
            } catch (error) {

                this.$swal({
                    title: "Error",
                    text: error.response.data.message,
                    icon: "error",
                    customClass: {
                        confirmButton: "btn btn-danger",
                    },
                    buttonsStyling: false,
                });
            }
        },
        getEditLink(id) {
            return "/servicerequest/edit/" + id;
        },
        getProduct(id) {
            const product = this.productList.find((s) => s.id === id);
            return product ? product.name : "";
        },
        getCustomer(id) {
            const customer = this.customerList.find((s) => s.id === id);
            return customer ? customer.otherName : "";
        },
        getSalePersonName(id) {
            const salesPerson = this.SalesPersonList.find((s) => s.id === id);
            return salesPerson ? salesPerson.firstName + " " + salesPerson.lastName : "";
        },
        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`;
            this.infoModal.content = JSON.stringify(item, null, 2);
            this.$root.$emit("bv::show::modal", this.infoModal.id, button);
        },
        resetInfoModal() {
            this.infoModal.title = "";
            this.infoModal.content = "";
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
    },
};
</script>
  